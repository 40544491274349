<template>
  <v-container class="background" fluid fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="5" lg="4" xl="3">
        <v-card class="px-8 py-12 rounded-lg" color="surface">
          <h1 class="text-body-1 text-center">Administrativo</h1>

          <h1 class="text-h5 font-weight-bold text-center mb-10">
            Arena Jogue Fácil
          </h1>

          <v-form v-model="valid" ref="form">
            <div @keypress.enter.prevent="submit()" class="mb-4">
              <custom-input
                v-model="form.email"
                ref="email"
                label="E-mail"
                placeholder="Endereço de e-mail"
                :currentValue="form.email"
                @keydown.space.prevent
              />

              <custom-input
                v-model="form.password"
                label="Senha"
                placeholder="Sua senha"
                type="password"
                :currentValue="form.password"
                @keydown.space.prevent
              />
            </div>

            <v-btn
              class="text-none black--text"
              color="primary"
              block
              large
              @click.prevent="submit()"
            >
              Entrar na plataforma
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { signIn } from "@/services/arena/auth.js";
import { displayAlert } from "@/utils";

export default {
  name: "Auth",

  data() {
    return {
      loading: false,
      valid: false,
      form: {
        email: "",
        password: "",
      },
    };
  },

  mounted() {
    this.$refs.email.handleInput(localStorage.getItem("ajf_email") || "");
  },

  methods: {
    ...mapActions(["setSignIn"]),

    // request to api
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        await signIn(this.form).then((res) => {
          localStorage.setItem("ajf_email", this.form.email);

          this.setSignIn({
            user: res.body.user,
            token: res.body.token,
          });

          this.$router.push({ path: "/home" });
        });
      } catch (err) {
        const message = err.data ? err.data.message : err.data.message || err;
        this.displayAlert(message, 1);
      } finally {
        this.loading = false;
      }
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped></style>
